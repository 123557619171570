var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hybrid-room-audio-settings" },
    [
      _vm._l(_vm.options, function (value, key) {
        return _c(
          "label",
          {
            key: key,
            staticClass: "mt-3 hybrid-room-audio-settings__radio-btn",
            class:
              _vm.option === value
                ? "hybrid-room-audio-settings__radio-btn--active"
                : null,
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.option,
                  expression: "option",
                },
              ],
              staticClass: "mr-2",
              attrs: { type: "radio", name: "hybrid-room-option" },
              domProps: { value: value, checked: _vm._q(_vm.option, value) },
              on: {
                change: function ($event) {
                  _vm.option = value
                },
              },
            }),
            _vm._v(" " + _vm._s(value) + " "),
          ]
        )
      }),
      _vm.option === _vm.Format.IRL_HOST_AND_IRL_PLAYERS ||
      _vm.option === _vm.Format.IRL_HOST_AND_REMOTE_PLAYERS
        ? [
            _c("WeveCheckbox", {
              staticClass: "hybrid-room-audio-settings__checkbox mt-4",
              attrs: { label: "Headphones" },
              model: {
                value: _vm.headphones,
                callback: function ($$v) {
                  _vm.headphones = $$v
                },
                expression: "headphones",
              },
            }),
            _c(
              "p",
              { staticClass: "hybrid-room-audio-settings__checkbox-tip" },
              [_vm._v(" To hear virtual teams ")]
            ),
          ]
        : _vm._e(),
      _vm.headphones &&
      (_vm.option === _vm.Format.IRL_HOST_AND_IRL_PLAYERS ||
        _vm.option === _vm.Format.IRL_HOST_AND_REMOTE_PLAYERS)
        ? [
            _c("WeveCheckbox", {
              staticClass: "hybrid-room-audio-settings__checkbox mt-2",
              attrs: { label: "Monitoring" },
              model: {
                value: _vm.monitoring,
                callback: function ($$v) {
                  _vm.monitoring = $$v
                },
                expression: "monitoring",
              },
            }),
            _c(
              "p",
              { staticClass: "hybrid-room-audio-settings__checkbox-tip" },
              [_vm._v(" To hear in-person teams ")]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }