

























































import { defineComponent, computed, ref } from "@vue/composition-api"

import useStore from "@/use/useStore"
import { db } from "@/firebase"
import UserService from "@/services/user.service"

import { WeveCheckbox } from "@weve/ui"

enum Format {
  REMOTE_HOST_AND_REMOTE_PLAYERS = "On-Screen Host",
  IRL_HOST_AND_IRL_PLAYERS = "IRL Host, IRL Players",
  IRL_HOST_AND_REMOTE_PLAYERS = "IRL Host, Hybrid Players"
}

export default defineComponent({
  name: "HybridRoomAudioSettings",
  components: { WeveCheckbox },
  setup() {
    const database = db.auxiliary()
    const { store } = useStore()
    const viewer = computed(() => store.state.auth.user)
    const orgId = computed<string>(() => store.getters.orgID)
    const roomId = computed<string>(() => store.getters.gameID)
    const hasIRLUsers = computed<boolean>(
      () => store.getters["auth/hasIRLUsers"]
    )
    const isHostIRL = computed<boolean>(() => store.getters["auth/isHostIRL"])
    const options = ref(Format)

    const monitoring = computed({
      get() {
        return Boolean(viewer.value?.monitoring)
      },
      set(value) {
        UserService.updateMonitoring(viewer.value, Boolean(value))
      }
    })

    const headphones = computed({
      get() {
        return Boolean(viewer.value?.headphones)
      },
      set(value) {
        UserService.updateHeadphones(viewer.value, Boolean(value))
      }
    })

    const option = computed<Format>({
      get() {
        if (hasIRLUsers.value && isHostIRL.value) {
          return Format.IRL_HOST_AND_IRL_PLAYERS
        }

        if (isHostIRL.value) {
          return Format.IRL_HOST_AND_REMOTE_PLAYERS
        }

        return Format.REMOTE_HOST_AND_REMOTE_PLAYERS
      },
      set(value) {
        //default for Format.REMOTE_HOST_AND_REMOTE_PLAYERS
        let allUsersIRL = false
        let hostIRL = false

        if (value === Format.IRL_HOST_AND_IRL_PLAYERS) {
          allUsersIRL = true
          hostIRL = true
        }

        if (value === Format.IRL_HOST_AND_REMOTE_PLAYERS) {
          hostIRL = true
        }

        database.ref(`org/${orgId.value}/games/${roomId.value}/`).update({
          allUsersIRL,
          hostIRL
        })
      }
    })

    return { options, option, monitoring, headphones, Format }
  }
})
